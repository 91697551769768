import { API_HOST, INTERNAL_API_HOST, INTERNAL_USER_AGENT } from "./apiHelpers";

const parseCategoriesResponse = (
  body: CategoriesResponse
): CategoriesParseResponse => ({
  categories: body["data"],
  pagination: body["meta"],
});

export const getCategories = async (
  language = "en",
  orderBy = "name"
): Promise<CategoriesParseResponse> => {
  return fetch(
    `${API_HOST}/categories?language=${language}&perPage=30&orderBy=${orderBy}`
  )
    .then((r: Response) => r.json())
    .then(parseCategoriesResponse)
    .catch((e) => {
      console.error(e);
      return null;
    });
};

// TODO refactor to share code with getCategories
export const getCategoriesInternally = async (
  language = "en"
): Promise<CategoriesParseResponse> => {
  return fetch(
    `${INTERNAL_API_HOST}/categories?language=${language}&perPage=30`,
    {
      headers: new Headers({ "User-Agent": INTERNAL_USER_AGENT }),
    }
  )
    .then((r: Response) => r.json())
    .then(parseCategoriesResponse)

    .catch((e) => {
      console.error(e);
      return null;
    });
};
