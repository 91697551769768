import { processLrmText } from "@helpers/processLrmText";

interface TextDirectionProps {
  text: string;
}

const TextDirection: React.FC<TextDirectionProps> = ({ text }) => {
  if (!text) return <></>;
  // list of text that has to always be LTR, even when main locale is RTL
  const ltrTextList = ["Apple TV+"];
  const hasLtrText = ltrTextList.find((ltrText: string) =>
    text.includes(ltrText)
  );

  if (!hasLtrText)
    return <span dangerouslySetInnerHTML={{ __html: text }}></span>;

  return (
    <>
      <span
        data-testid="ltr-text"
        dangerouslySetInnerHTML={{ __html: processLrmText(text, ltrTextList) }}
      ></span>
    </>
  );
};

export default TextDirection;
