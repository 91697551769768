import Modal from "@components/Modal";
import { useSiteConfig } from "@context/siteConfig";
import { useTranslations } from "@context/translations";
import ArrowIcon from "@icons/longArrowDown.svg";
import clsx from "clsx";
import { FC } from "react";
import styles from "./WhatsTheDiff.module.scss";

interface WhatsTheDiffProps {
  onClose: () => void;
}

const modalStyle = {
  "<1024": {
    width: "560px",
    marginTop: "56px",
    maxHeight: "calc(100vh - 56px - 16px - 40px)",
  },
  ">=1024": {
    width: "560px",
    marginTop: "48px",
    height: "842px",
  },
};

const WhatsTheDiff: FC<WhatsTheDiffProps> = ({ onClose }) => {
  const { isMobile, isRtl } = useSiteConfig();
  const translate = useTranslations();

  const createMarkup = (html: string) => {
    return { __html: html };
  };

  return (
    <Modal onCloseClick={onClose} modalStyle={modalStyle} fullScreen={isMobile}>
      <div className={clsx(styles.container, isRtl && styles.rtl)}>
        <div className={styles.header}>{translate("tooltip_header")}</div>

        <div>
          <div className={styles.subHeader}>
            {translate("available_balance")}
          </div>
          <div>{translate("tooltip_available_desc")}</div>
        </div>

        <div>
          <div className={styles.subHeader}>
            {translate("tooltip_pending_balance")}
          </div>
          <div>{translate("tooltip_pending_desc")}</div>
        </div>

        <div>
          <div className={styles.subHeader}>{translate("onboard_title_b")}</div>

          <div className={styles.illustrationContainer}>
            <img
              src="https://assets.wego.com/image/upload/v1607931359/web/shopcash/illustrations-shop-and-earn.png"
              alt="Shop and earn"
              width={100}
              height={100}
            />
            <div className={styles.textContainer}>
              <div className={styles.subSubHeader}>
                {translate("tooltip_shop_earn")}
              </div>
              <div>
                <p
                  dangerouslySetInnerHTML={createMarkup(
                    translate("tooltip_shop_earn_description")
                  )}
                />
              </div>
            </div>

            <ArrowIcon />
            <div></div>

            <img
              src="https://assets.wego.com/image/upload/v1607931434/web/shopcash/illustrations-store-approves.png"
              alt="Store approves"
              width={100}
              height={100}
            />
            <div className={styles.textContainer}>
              <div className={styles.subSubHeader}>
                {translate("tooltip_store_approves")}
              </div>
              <div>
                <p
                  dangerouslySetInnerHTML={createMarkup(
                    translate("tooltip_store_approves_description")
                  )}
                />
              </div>
            </div>

            <ArrowIcon />
            <div></div>

            <img
              src="https://assets.wego.com/image/upload/v1607931485/web/shopcash/illustrations-get-cashback.png"
              alt="Get cashback"
              width={100}
              height={100}
            />
            <div className={styles.textContainer}>
              <div className={styles.subSubHeader}>
                {translate("tooltip_get_cashback")}
              </div>
              <div>
                <p
                  dangerouslySetInnerHTML={createMarkup(
                    translate("tooltip_get_cashback_description")
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default WhatsTheDiff;
