import { getNotifications as listNotifications } from "@api/notifications";
import Button from "@components/Button";
import NotificationList from "@components/NotificationList";
import { useAuth } from "@context/authentication";
import { useSiteConfig } from "@context/siteConfig";
import { useGenzoTracking } from "@context/tracking/genzo";
import { useTranslations } from "@context/translations";
import { getSeeAllNotificationsEvent } from "@helpers/genzo/genzoEventActionsBuilder";
import { useOutOfBounds } from "@helpers/useOutOfBounds";
import { VariantButton } from "@shopcashTypes/variantButton";
import clsx from "clsx";
import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";
import styles from "./NotificationToolTip.module.scss";

interface NotificationToolTipProps {
  onClose: () => void;
}

const NotificationToolTip: React.FC<NotificationToolTipProps> = ({
  onClose,
}) => {
  const [notifications, setNotifications] = useState(undefined);
  const translate = useTranslations();
  const router = useRouter();

  const { accessToken } = useAuth();
  const { locale, isRtl } = useSiteConfig();
  const { trackEvent } = useGenzoTracking();

  const tooltipRef = useRef();
  const outOfBounds = useOutOfBounds(tooltipRef);
  const [shiftAmount, setShiftAmount] = useState(0);

  useEffect(() => {
    const timezoneOffset = new Date().getTimezoneOffset();
    listNotifications(accessToken, locale, timezoneOffset, 1, 3)
      .then((results) => setNotifications(results.notifications))
      .catch((e) => console.error(e));
  }, [accessToken, locale]);

  useEffect(() => {
    const sidePaddingAmount = 16;

    if (outOfBounds.left > 0) {
      setShiftAmount(-outOfBounds.left - sidePaddingAmount);
    }

    if (outOfBounds.right > 0) {
      setShiftAmount(outOfBounds.right + sidePaddingAmount);
    }
  }, [outOfBounds]);

  const handleSeeAllNotificationsClick = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    router.push({
      pathname: `/account/notifications`,
    });

    handleSeeAllEvent();
  };

  const handleSeeAllEvent = () => {
    const event = getSeeAllNotificationsEvent();
    trackEvent(event);
  };

  return (
    <>
      <div className={styles.tooltipTriangle}></div>
      <div
        ref={tooltipRef}
        className={clsx(
          styles.notificationToolTip,
          notifications?.length == 0 && styles.empty
        )}
        style={{
          transform: `translateX(calc(-50% - ${shiftAmount}px))`,
        }}
      >
        <div
          className={clsx(styles.close, isRtl && styles.rtl)}
          onClick={() => {
            onClose();
          }}
        >
          ✕
        </div>
        <h6 className={styles.header}>{translate("title_notifications")}</h6>
        <div
          className={clsx(
            styles.body,
            notifications !== undefined && styles.fadeIn
          )}
        >
          <NotificationList
            compact
            notifications={notifications}
            loadingCount={1}
          />
        </div>
        {notifications?.length > 0 && (
          <div className={styles.footer}>
            <div
              data-testid="notifications-tooltip-button-div"
              className={styles.fadeIn}
            >
              <Button
                variant={VariantButton.Primary}
                onClick={handleSeeAllNotificationsClick}
                fullWidth
              >
                {translate("notif_title_see_all")}
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default NotificationToolTip;
