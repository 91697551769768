import { useSiteConfig } from "@context/siteConfig";
import { useWebEngageTracking } from "@context/tracking/webEngage";
import { buildLanguageClickAttrs } from "@helpers/webEngage/clickEventBuilders";
import { WebEngageClickEvent } from "@shopcashTypes/webEngageTracking";
import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import styles from "./LanguageSelector.module.scss";

interface LanguageSelectorProps {
  isRtl: boolean;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({ isRtl }) => {
  const router = useRouter();
  const { trackWebEngageClickEvent } = useWebEngageTracking();
  const { locale, defaultLocale } = useSiteConfig();
  const handleLocaleClick = (locale: string) => {
    trackWebEngageClickEvent(
      WebEngageClickEvent.languageClick,
      buildLanguageClickAttrs(locale)
    );
  };

  return (
    <div
      data-cy="language-selector"
      data-testid="language-selector-header"
      className={clsx(styles.languageSelector, isRtl && styles.rtl)}
    >
      <div
        className={clsx(styles.language, locale === "en" && styles.selected)}
      >
        <Link
          href={{
            pathname: router.pathname,
            query: router.query,
          }}
          locale={defaultLocale.includes("en") ? defaultLocale : "en"}
        >
          <a onClick={() => handleLocaleClick("English")}>EN</a>
        </Link>
      </div>
      <div
        className={clsx(styles.language, locale === "ar" && styles.selected)}
      >
        <Link
          href={{
            pathname: router.pathname,
            query: router.query,
          }}
          locale={defaultLocale.includes("ar") ? defaultLocale : "ar"}
        >
          <a onClick={() => handleLocaleClick("Arabic")}>AR</a>
        </Link>
      </div>
    </div>
  );
};

export default LanguageSelector;
