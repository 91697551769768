import HeartIcon from "@icons/heart.svg";
import { VariantHeart } from "@shopcashTypes/variantHeart";
import clsx from "clsx";
import { useEffect, useState } from "react";
import styles from "./Heart.module.scss";

interface HeartProps {
  variant: VariantHeart;
  isFavourited?: boolean;
  hasHoverAnimation?: boolean;
  onClick: () => void;
  width: string;
  height: string;
}

const Heart: React.FC<HeartProps> = ({
  variant = "grey",
  isFavourited = false,
  hasHoverAnimation = true,
  onClick,
  width,
  height,
}) => {
  const [isLiked, setIsLiked] = useState(isFavourited);
  const [isClicked, setIsClicked] = useState(false);

  const handleOnClick = async (): Promise<void> => {
    setIsClicked(true);
    const response = await onClick();
    setIsClicked(false);
  };

  useEffect(() => {
    setIsLiked(isFavourited);
  }, [isFavourited]);

  return (
    <div
      className={clsx(
        hasHoverAnimation && styles.animatedHover,
        styles.container
      )}
      data-testid="heart-icon"
      onClick={() => {
        isClicked ? null : handleOnClick();
      }}
    >
      <div
        className={clsx(
          styles.heart,
          !isLiked && variant && styles[variant],
          isLiked && styles[VariantHeart.Filled],
          isClicked && styles.clicked
        )}
      >
        <HeartIcon width={width} height={height} />
      </div>
    </div>
  );
};

export default Heart;
