import TextDirection from "@components/_shared/TextDirection";
import DealResult from "@icons/dealResult.svg";
import StoreResult from "@icons/storeResult.svg";
import { SearchResultType } from "@shopcashTypes/searchResultType";
import clsx from "clsx";
import Link from "next/link";
import styles from "./SearchResult.module.scss";

interface SearchResultProps {
  description?: string;
  id: string;
  name: string;
  onClick: (id: string, type: SearchResultType) => void;
  slug: string;
  type: SearchResultType;
  isRtl: boolean;
}

const SearchResult: React.FC<SearchResultProps> = ({
  description,
  id,
  name,
  onClick,
  slug,
  type,
  isRtl,
}) => {
  let resultIcon: React.ReactNode;
  let queryParam: { deal: string };
  switch (type) {
    case SearchResultType.DEAL:
      resultIcon = <DealResult />;
      queryParam = { deal: id };
      break;
    case SearchResultType.STORE:
      resultIcon = <StoreResult />;
      break;
  }

  return (
    <li
      className={clsx(styles.searchResult, isRtl && styles.isRtl)}
      onClick={() => onClick(id, type)}
    >
      <Link href={{ pathname: `/store/${slug}`, query: queryParam }}>
        <a>
          {resultIcon}
          <div className={styles.textDirection}>
            <TextDirection text={name} />
          </div>
          {description && (
            <span className={styles.description}>{description}</span>
          )}
        </a>
      </Link>
    </li>
  );
};

export default SearchResult;
