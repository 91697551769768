import { useSiteConfig } from "@context/siteConfig";
import { useTranslations } from "@context/translations";
import clsx from "clsx";
import Link from "next/link";
import styles from "./CategoryMenu.module.scss";

interface CategoryMenuProps {
  basePath?: string;
  categories: Array<Category>;
  activeCategorySlug?: string;
  title: string;
  onCategoryClick?: (categoryId: string, index: number) => void;
}

const allStore: Category = {
  id: "all-stores",
  name: "footer_all_stores",
  enName: "All Stores",
  slug: "all-stores",
};

const CategoryMenu: React.FC<CategoryMenuProps> = ({
  basePath = "category",
  categories,
  activeCategorySlug,
  title,
  onCategoryClick,
}) => {
  const { isRtl } = useSiteConfig();
  const translate = useTranslations();

  const allCategories =
    categories[0]?.name === allStore.name
      ? categories
      : [allStore].concat(categories);

  const handleOnCategoryClick = (categoryId: string, index: number): void => {
    onCategoryClick && onCategoryClick(categoryId, index);
  };

  return (
    <div
      data-testid="category-menu"
      className={clsx(styles.container, isRtl && styles.rtl)}
    >
      <div className={styles.heading}>{title}</div>
      <div className={styles.content}>
        {allCategories.map((c, i) => (
          <div key={i} className={styles.item}>
            <Link href={`/${basePath}/${c.slug}`}>
              <a
                className={clsx(activeCategorySlug === c.slug && styles.active)}
                onClick={() => handleOnCategoryClick(c.id, i)}
              >
                {c.name === "footer_all_stores"
                  ? translate("footer_all_stores")
                  : c.name}
              </a>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryMenu;
