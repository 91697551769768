const escapeRegex = (string) =>
  string.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");

export const processLrmText = (txt: string, ltrTextList: string[]) => {
  const replaceMatchReducer = (previousValue: string, match: string) => {
    const matchRegex = new RegExp(escapeRegex(match), "g");
    return previousValue.replace(matchRegex, `${match}&lrm;`); //note: can't use string.replaceAll for some reason
  };

  return ltrTextList.reduce(replaceMatchReducer, txt);
};
