import { useSiteConfig } from "@context/siteConfig";
import { useTranslations } from "@context/translations";
import CrossIcon from "@icons/cross.svg";
import clsx from "clsx";
import { useEffect, useState } from "react";
import styles from "./Modal.module.scss";

interface Dimensions {
  width?: string | number;
  height?: string | number;
  marginTop?: string;
  marginBottom?: string;
  maxWidth?: string | number;
  maxHeight?: string | number;
}

interface modalStyle {
  "<1024"?: Dimensions;
  ">=1024"?: Dimensions;
}

interface ModalProps {
  children: React.ReactNode;
  onCloseClick: () => void;
  fullScreen?: boolean;
  modalStyle?: modalStyle;
}

const signUpModalStyle = {
  "<1024": {
    width: "407px",
    marginTop: "56px",
    maxHeight: "calc(100vh - 56px - 16px - 40px)",
  },
  ">=1024": {
    width: "742px",
    marginTop: "48px",
    // height of modal:
    // 593px fix height
    // + 24px close button height
    // + 16px space between button and modal. (i.e. margin)
    height: "calc(593px + 24px + 16px)",
  },
};

const Modal: React.FC<ModalProps> = ({
  children,
  onCloseClick,
  fullScreen,
  modalStyle = signUpModalStyle,
}) => {
  const { isRtl } = useSiteConfig();
  const translate = useTranslations();
  const [screenWidth, setScreenWidth] = useState<number>(null);

  useEffect(() => {
    const bodyClassList = document.body.classList;

    bodyClassList.add("bodyScrollDisabled");

    return () => {
      bodyClassList.remove("bodyScrollDisabled");
    };
  }, []);

  const getScreenWidth = (): number => {
    return window.innerWidth;
  };

  useEffect(() => {
    setScreenWidth(getScreenWidth());
  }, []);

  const getmodalStyle = (): Dimensions | undefined => {
    if (screenWidth < 768) {
      return {
        width: "100%",
        height: "100%",
        marginTop: "0px",
        maxWidth: "100%",
        maxHeight: "100%",
      };
    }

    if (screenWidth < 1024) {
      return modalStyle?.["<1024"];
    }

    if (screenWidth >= 1024) {
      return modalStyle?.[">=1024"];
    }
  };

  return (
    <div
      className={clsx(
        styles.modal,
        fullScreen && styles.fullScreen,
        isRtl && styles.rtl
      )}
    >
      <div
        className={styles.container}
        style={{
          width: getmodalStyle()?.width || "fit-content",
          height: getmodalStyle()?.height || "fit-content",
          marginTop: getmodalStyle()?.marginTop || "48px",
          marginBottom: getmodalStyle()?.marginBottom || "16px",
          maxWidth: getmodalStyle()?.maxWidth || "80vw",
          // 100 viewport height minus 48px marginTop and 16px marginBottom, and 40px.
          // 40px calculation is:
          //  - 16px space between modal and close button.
          //  - 24px height of close button.
          maxHeight:
            getmodalStyle().maxHeight || "calc(100vh - 48px - 16px - 40px)",
        }}
      >
        <div className={styles.secondaryContainer}>
          <div
            className={clsx(
              styles.closeContainer,
              fullScreen && styles.fullScreen
            )}
            onClick={onCloseClick}
            data-testid="modal-close"
          >
            {fullScreen ? (
              <div>{translate("close_title")}</div>
            ) : (
              <CrossIcon className={styles.closeIcon} />
            )}
          </div>
          <div className={styles.wrap}>
            <div className={styles.childrenContainer}>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
