import { RefObject, useEffect, useState } from "react";

interface outOfBoundsObj {
  top: number;
  bottom: number;
  left: number;
  right: number;
}

export const useOutOfBounds = (
  componentRef: RefObject<HTMLDivElement>
): outOfBoundsObj => {
  const [isOutOfBounds, setIsOutOfBounds] = useState({
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  });

  useEffect(() => {
    if (componentRef?.current) {
      const rect = componentRef.current.getBoundingClientRect();

      const windowWidth = Math.min(
        document.documentElement.clientWidth,
        window.innerWidth
      );
      const windowHeight = Math.min(
        document.documentElement.clientHeight,
        window.innerHeight
      );

      const directions = {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      };

      if (rect.top < 0) {
        directions.top = Math.abs(0 - rect.top);
      }

      if (rect.bottom > windowHeight) {
        directions.bottom = Math.abs(windowHeight - rect.bottom);
      }

      if (rect.left < 0) {
        directions.left = Math.abs(0 - rect.left);
      }

      if (rect.right > windowWidth) {
        directions.right = Math.abs(windowWidth - rect.right);
      }

      if (
        isOutOfBounds.top !== directions.top ||
        isOutOfBounds.bottom !== directions.bottom ||
        isOutOfBounds.left !== directions.left ||
        isOutOfBounds.right !== directions.right
      ) {
        setIsOutOfBounds(directions);
      }
    }
  }, [componentRef]);

  return isOutOfBounds;
};
