import { useAuth } from "@context/authentication";
import { useTranslations } from "@context/translations";
import ExclamationIcon from "@icons/exclamation.svg";
import { FC } from "react";
import styles from "./WalletSummary.module.scss";

interface WalletSummaryProps {
  onWhatsTheDiffShow: () => void;
}

const WalletSummary: FC<WalletSummaryProps> = ({ onWhatsTheDiffShow }) => {
  const { user } = useAuth();
  const translate = useTranslations();

  if (!user) return null;

  return (
    <div className={styles.container}>
      <div className={styles.profile}>
        <div className={styles.name}>{user.profile.name}</div>
        <div className={styles.heading}>{translate("available_balance")}</div>
        <div className={styles.amount}>
          {user.ledger.currencyCode}&nbsp;
          {(user.ledger.availableBalance || 0).toFixed(2)}
        </div>
        <div className={styles.heading}>{translate("pending_balance")}</div>
        <div className={styles.amount}>
          {user.ledger?.currencyCode}&nbsp;
          {(user.ledger?.pendingBalance || 0).toFixed(2)}
        </div>
      </div>
      <div className={styles.difference} onClick={onWhatsTheDiffShow}>
        <ExclamationIcon /> <span>{translate("tooltip_header")}</span>
      </div>
    </div>
  );
};

export default WalletSummary;
