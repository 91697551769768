import { getPayouts } from "@api/account";
import Button from "@components/Button";
import HowToUseCashBack from "@components/HowToUseCashback";
import HowToUseCashbackCard from "@components/HowToUseCashBackCard";
import WalletSummary from "@components/WalletSummary";
import WelcomeMessage from "@components/WelcomeMessage";
import { useAuth } from "@context/authentication";
import { useSiteConfig } from "@context/siteConfig";
import { useGenzoTracking } from "@context/tracking/genzo";
import { useWebEngageTracking } from "@context/tracking/webEngage";
import { useTranslations } from "@context/translations";
import { getCookie, setCookie } from "@helpers/cookie";
import {
  getHeaderSignInEvent,
  getHeaderSignUpEvent,
  getHowToUseCashbackClickEvent,
} from "@helpers/genzo/genzoEventActionsBuilder";
import AccountIcon from "@icons/account.svg";
import CrossIcon from "@icons/cross.svg";
import FavouriteIcon from "@icons/favourite.svg";
import GenericProfile from "@icons/genericProfile.svg";
import ReferIcon from "@icons/refer.svg";
import WalletIcon from "@icons/wallet.svg";
import { VariantButton } from "@shopcashTypes/variantButton";
import { WebEngageUserEventType } from "@shopcashTypes/webEngageTracking";
import clsx from "clsx";
import Link from "next/link";
import { forwardRef, useEffect, useState } from "react";
import styles from "./Profile.module.scss";

const LoggingIn = () => {
  const { isRtl } = useSiteConfig();

  return (
    <div
      className={clsx(styles.container, isRtl && styles.rtl, styles.loading)}
    >
      <div className={styles.menuContainer}>
        <div className={clsx(styles.profileMenu, styles.borderless)}>
          <div className={styles.boxLarge}></div>
          <div className={styles.boxSmall}></div>
          <div className={styles.boxMedium}></div>
        </div>
      </div>
    </div>
  );
};

interface ProfileProps {
  onJoinNowClick: (value: boolean) => void;
  onSignInClick: (value: boolean) => void;
  isProfileMenuOpen: boolean;
  setIsProfileMenuOpen: (setStateAction: React.SetStateAction<boolean>) => void;
  onWhatsTheDiffShow: () => void;
}

const Profile = forwardRef<HTMLDivElement, ProfileProps>(
  (
    {
      onJoinNowClick,
      onSignInClick,
      onWhatsTheDiffShow,
      isProfileMenuOpen,
      setIsProfileMenuOpen,
    },
    ref
  ) => {
    const {
      user,
      logout,
      isAuthenticating,
      accessToken,
      refreshUser,
    } = useAuth();
    const translate = useTranslations();
    const { isRtl, locale } = useSiteConfig();
    const [showWelcomeMessage, setShowWelcomeMessage] = useState(false);
    const [showHowToUseCashback, setShowHowToUseCashback] = useState<boolean>(
      false
    );
    const [showHowToUseCashbackInfo, setShowHowToUseCashbackInfo] = useState(
      false
    );
    const { trackEvent, trackUserEvent } = useGenzoTracking();
    const { trackWebEngageUserEvent } = useWebEngageTracking();
    const [firstPayout, setFirstPayout] = useState<Payout>();

    useEffect(() => {
      if (user) {
        const welcomeMessageClosedBy = getCookie(
          `welcomeMessageClosedBy:${user.id}`
        );
        setShowWelcomeMessage(welcomeMessageClosedBy !== "true");
      }
    }, [user]);

    const fetchPayoutData = async () => {
      try {
        const { payouts } = await getPayouts(accessToken, locale, 1, 2);
        if (payouts.length === 1) setFirstPayout(payouts[0]);
        refreshUser(accessToken);
      } catch (error) {
        console.error("Error fetching payouts: ", error);
      }
    };

    useEffect(() => {
      const hasEarnedCashback = getCookie(`hasEarnedCashback:${user?.id}`);
      if (user?.id && showWelcomeMessage && !hasEarnedCashback) {
        const timer = setTimeout(fetchPayoutData, 3000);

        return () => clearTimeout(timer);
      }
    }, [user?.id, showWelcomeMessage]);

    const { ledger } = user || {};

    const handleOnClickMenu = async () => {
      try {
        const hasEarnedCashback = getCookie(`hasEarnedCashback:${user.id}`);
        const payouts = (await getPayouts(accessToken, locale, 1, 2)).payouts;

        if (hasEarnedCashback) {
          setShowHowToUseCashback(false);
        } else if (payouts.length === 1) {
          const payoutType = payouts[0].payoutType;
          if (payoutType === 2 || payoutType === 3) {
            setFirstPayout(payouts[0]);
            setShowHowToUseCashback(true);
          } else {
            setShowHowToUseCashback(false);
            setCookie(`hasEarnedCashback:${user.id}`, true);
          }
        } else {
          setShowHowToUseCashback(false);
          setCookie(`hasEarnedCashback:${user.id}`, true);
        }
      } catch (error) {
        console.error("Error fetching payouts: ", error);
      } finally {
        setIsProfileMenuOpen((prevState) => !prevState);
        setShowWelcomeMessage(false);
        setCookie(`welcomeMessageClosedBy:${user.id}`, true);
      }
    };

    const handleOnWelcomeMesageClose = () => {
      setCookie(`welcomeMessageClosedBy:${user.id}`, true);
      setShowWelcomeMessage(false);
    };

    const handleOnLogoutClick = () => {
      trackUserEvent("USER_LOGOUT");
      trackWebEngageUserEvent({ type: WebEngageUserEventType.Logout });
      logout();
    };

    const handleHeaderSignInEvent = () => {
      const event = getHeaderSignInEvent();
      trackEvent(event);
    };
    const handleHeaderSignUpEvent = () => {
      const event = getHeaderSignUpEvent();
      trackEvent(event);
    };

    const handleUserEducationSignUpBonusOpen = () => {
      trackEvent(getHowToUseCashbackClickEvent());
      setShowHowToUseCashbackInfo(true);
      setIsProfileMenuOpen(false);
    };

    const handleUserEducationSignUpBonusClose = () => {
      setShowHowToUseCashbackInfo(false);
    };

    if (!isAuthenticating && user) {
      return (
        <div className={clsx(styles.container, isRtl && styles.rtl)}>
          <div
            className={styles.menuContainer}
            onClick={handleOnClickMenu}
            onBlur={(e) => {
              if (!e.currentTarget.contains(e.relatedTarget as Node)) {
                setIsProfileMenuOpen(false);
              }
            }}
            tabIndex={0}
            ref={ref}
            data-testid="profile-menu"
          >
            <div
              className={clsx(
                styles.profileMenu,
                isProfileMenuOpen && styles.active
              )}
            >
              <div className={styles.profileIconContainer}>
                <GenericProfile />
              </div>
              <span className={styles.walletBalance}>
                {ledger.currencyCode}{" "}
                {(ledger.availableBalance + ledger.pendingBalance || 0).toFixed(
                  2
                )}
              </span>
              <div className={styles.arrowIcon}></div>
            </div>

            {isProfileMenuOpen && (
              <div
                className={clsx(styles.dropdownMenu, isRtl && styles.rtl)}
                onClick={(e) => e.stopPropagation()}
              >
                <WalletSummary onWhatsTheDiffShow={onWhatsTheDiffShow} />

                {showHowToUseCashback && (
                  <HowToUseCashbackCard
                    handleUserEducationSignUpBonusOpen={
                      handleUserEducationSignUpBonusOpen
                    }
                  />
                )}

                <Link href="/wallet">
                  <a data-testid="profile-menu-wallet-link">
                    <WalletIcon />
                    {translate("wallet")}
                  </a>
                </Link>
                <Link href="/account/favorite-stores">
                  <a data-testid="profile-menu-favorite-stores-link">
                    <FavouriteIcon />
                    {translate("favorite_stores_title")}
                  </a>
                </Link>
                <Link href="/wallet/refer">
                  <a data-testid="profile-menu-refer-and-earn-link">
                    <ReferIcon />
                    {translate("refer_and_earn")}
                  </a>
                </Link>
                <Link href="/account">
                  <a data-testid="profile-menu-account-link">
                    <AccountIcon />
                    {translate("account")}
                  </a>
                </Link>
                <a onClick={handleOnLogoutClick}>{translate("logout")}</a>
              </div>
            )}
          </div>
          {showWelcomeMessage && (
            <div className={styles.welcomeMenu}>
              <div
                data-testid="welcome-message-close"
                className={styles.closeIconContainer}
                onClick={handleOnWelcomeMesageClose}
              >
                <CrossIcon />
              </div>
              <WelcomeMessage
                showUserEducationSignUpBonus={
                  handleUserEducationSignUpBonusOpen
                }
                firstPayout={firstPayout}
              />
            </div>
          )}
          {showHowToUseCashbackInfo && (
            <HowToUseCashBack
              onClose={handleUserEducationSignUpBonusClose}
              firstPayout={firstPayout}
            />
          )}
        </div>
      );
    } else if (!isAuthenticating && !user) {
      return (
        <div className={clsx(styles.container, isRtl && styles.rtl)}>
          <div className={styles.authButtons} data-testid="header-auth-button">
            <div
              className={styles.signInLink}
              data-testid="header-signin-button"
              onClick={() => {
                onSignInClick(true);
                handleHeaderSignInEvent();
              }}
            >
              {translate("onboarding_signin")}
            </div>
            <Button
              variant={VariantButton.Secondary}
              onClick={() => {
                handleHeaderSignUpEvent();
                onJoinNowClick(true);
              }}
            >
              {translate("join_now")}
            </Button>
          </div>
        </div>
      );
    } else {
      return <LoggingIn />;
    }
  }
);

export default Profile;
