import NotificationItem from "@components/NotificationItem";
import { useTranslations } from "@context/translations";
import clsx from "clsx";
import styles from "./NotificationList.module.scss";

interface NotificationListProps {
  notifications: Array<Notification>;
  loadingCount: number;
  compact?: boolean;
}

const NotificationList: React.FC<NotificationListProps> = ({
  notifications,
  loadingCount,
  compact = false,
}) => {
  const scaffolds = [...Array(loadingCount)];
  const translate = useTranslations();

  return (
    <div
      className={clsx(
        styles.container,
        notifications === undefined && styles.loading
      )}
    >
      {notifications === undefined &&
        scaffolds.map((_, index) => (
          <NotificationItem compact={compact} key={index} isLoading={true} />
        ))}
      {notifications?.length > 0 &&
        notifications.map((notification) => (
          <NotificationItem
            compact={compact}
            key={notification.id}
            {...notification}
          />
        ))}
      {notifications?.length === 0 && (
        <div className={styles.emptyNotifications}>
          <img
            className={styles.image}
            src="https://assets.wego.com/image/upload/w_160/v1604479049/web/shopcash/illustrations-notifications-empty.png"
          />
          <p className={styles.msg}>{translate("notif_title_nonotifs")}</p>
        </div>
      )}
    </div>
  );
};

export default NotificationList;
