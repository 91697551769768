import {
  API_HOST_V2,
  INTERNAL_API_HOST_V2,
  INTERNAL_USER_AGENT,
  parseResponseBody,
} from "./apiHelpers";

export const searchShopCash = async (
  query: string,
  siteCode: string,
  locale = "en"
): Promise<SearchResult> => {
  const url = `${API_HOST_V2}/search?query=${encodeURIComponent(
    query
  )}&language=${locale}&siteCode=${siteCode}`;
  return fetch(url)
    .then((r) => r.json())
    .then((body: SearchResultResponse) =>
      parseResponseBody<SearchResultResponse, SearchResult>(body)
    )
    .catch((e) => {
      console.error(e);
      return null;
    });
};

export const searchShopCashInternally = async (
  query: string,
  siteCode: string,
  locale = "en"
): Promise<SearchResult> => {
  const url = `${INTERNAL_API_HOST_V2}/search?query=${encodeURIComponent(
    query
  )}&language=${locale}&siteCode=${siteCode}`;
  const headers = {
    headers: new Headers({ "User-Agent": INTERNAL_USER_AGENT }),
  };

  return fetch(url, headers)
    .then((r) => r.json())
    .then((body: SearchResultResponse) =>
      parseResponseBody<SearchResultResponse, SearchResult>(body)
    )
    .catch((e) => {
      console.error(e);
      return null;
    });
};
