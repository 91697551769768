import { useSiteConfig } from "@context/siteConfig";
import { useGenzoTracking } from "@context/tracking/genzo";
import { useTranslations } from "@context/translations";
import {
  getSearchResultClickEvent,
  getSearchResultSeeAllEvent,
} from "@helpers/genzo/genzoEventActionsBuilder";
import useDebounce from "@helpers/useDebounce";
import SearchIcon from "@icons/search.svg";
import { SearchResultType } from "@shopcashTypes/searchResultType";
import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import { FormEvent, useEffect, useState } from "react";
import styles from "./SearchInput.module.scss";
import SearchResult from "./SearchResult";

interface SearchInputProps {
  id: string;
  results: SearchResult;
  fullScreen?: boolean;
  onValueChange: (value: string) => void;
}

const SearchInput: React.FC<SearchInputProps> = ({
  results,
  fullScreen,
  onValueChange,
  ...props
}) => {
  const { isRtl } = useSiteConfig();
  const [isFocus, setIsFocus] = useState<boolean>(true);
  const [value, setValue] = useState<string>();
  const debouncedValue = useDebounce(value);
  const translate = useTranslations();
  const router = useRouter();
  const { trackEvent } = useGenzoTracking();

  const handleOnValueChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const newValue = e.target.value;
    setValue(newValue);
    onValueChange(newValue);
  };

  const handleOnResultClick = (
    storeId: string,
    type: SearchResultType
  ): void => {
    trackEvent(getSearchResultClickEvent(value, storeId, type));
    setIsFocus(false);
  };

  const handleSeeAllClick = (): void => {
    trackEvent(getSearchResultSeeAllEvent());
    setIsFocus(false);
  };

  const redirectToSearchPage = (e: FormEvent): void => {
    e.preventDefault();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    router.push({
      pathname: "/search",
      query: { q: value },
    });

    setIsFocus(false);
  };

  const getSearchResultName = (
    result: SearchDealResult | SearchStoreResult
  ): string => {
    return result["markupName"] || result["title"] || result["name"];
  };

  useEffect(() => {
    const bodyClassList = document.body.classList;

    isFocus && !!debouncedValue
      ? bodyClassList.add("bodyScrollDisabled")
      : bodyClassList.remove("bodyScrollDisabled");

    return () => {
      bodyClassList.remove("bodyScrollDisabled");
    };
  }, [isFocus, debouncedValue]);

  return (
    <>
      <div
        tabIndex={0}
        className={clsx(
          styles.container,
          isFocus && !!debouncedValue && styles.open,
          fullScreen && styles.fullScreen,
          isRtl && styles.rtl
        )}
        onFocus={() => {
          setIsFocus(true);
        }}
        onBlur={(e) => {
          if (!e.currentTarget.contains(e.relatedTarget as Node)) {
            setIsFocus(false);
          }
        }}
      >
        <div className={styles.wrapInput}>
          <form
            onSubmit={(e: React.FormEvent<HTMLFormElement>) =>
              redirectToSearchPage(e)
            }
          >
            <input
              type="text"
              autoComplete="off"
              placeholder={translate("search_placeholder")}
              onChange={handleOnValueChange}
              {...props}
            />
          </form>

          <label htmlFor={props.id}>
            <SearchIcon className={styles.icon} />
          </label>
        </div>

        {isFocus && !!debouncedValue && (
          <div className={styles.searchResultsContainer}>
            <ul>
              {results?.deals.length !== 0 && (
                <li className={styles.resultHeader}>{translate("deals")}</li>
              )}
              {results?.deals.slice(0, 3).map((deal) => (
                <SearchResult
                  key={deal.id}
                  id={deal.id}
                  name={getSearchResultName(deal)}
                  slug={deal.storeSlug}
                  onClick={handleOnResultClick}
                  type={SearchResultType.DEAL}
                  isRtl={isRtl}
                />
              ))}
              {results?.stores.length !== 0 && (
                <li className={styles.resultHeader}>{translate("stores")}</li>
              )}
              {results?.stores.slice(0, 5).map((store) => (
                <SearchResult
                  key={store.id}
                  id={store.id}
                  name={getSearchResultName(store)}
                  slug={store.slug}
                  description={store.cashbackText}
                  onClick={handleOnResultClick}
                  type={SearchResultType.STORE}
                  isRtl={isRtl}
                />
              ))}

              {results?.stores.length == 0 &&
                results?.deals.length == 0 &&
                !!debouncedValue && (
                  <li className={styles.noResults}>
                    {translate("no_results", [value])}
                  </li>
                )}
            </ul>

            {!!results?.stores && results.stores.length > 5 && (
              <div className={styles.seeAll} onClick={handleSeeAllClick}>
                <Link
                  href={{
                    pathname: "/search",
                    query: {
                      q: debouncedValue,
                    },
                  }}
                >
                  <a>{translate("see_all")}</a>
                </Link>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default SearchInput;

// -=-=-=-=-
// TO DOs
//  - Matching keywords are bolded.
