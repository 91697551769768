import styles from "./MobileHeader.module.scss";
import { searchShopCash } from "@api/search";
import AuthenticationModal from "@components/AuthenticationModal";
import Button from "@components/Button";
import Drawer from "@components/Drawer";
import NotificationBell from "@components/NotificationBell/NotificationBell";
import SearchInput from "@components/SearchInput";
import WelcomeMessage from "@components/WelcomeMessage";
import WhatsTheDiff from "@components/WhatsTheDiff";
import Modal from "@components/_shared/Modal";
import { useAuth } from "@context/authentication";
import { useSiteConfig } from "@context/siteConfig";
import { useGenzoTracking } from "@context/tracking/genzo";
import { useTranslations } from "@context/translations";
import { getCookie, setCookie } from "@helpers/cookie";
import { getSearchInputSearchEvent } from "@helpers/genzo/genzoEventActionsBuilder";
import useDebounce from "@helpers/useDebounce";
import CrossIcon from "@icons/cross.svg";
import MenuIcon from "@icons/menu.svg";
import SearchIcon from "@icons/search.svg";
import { AuthenticationStep } from "@shopcashTypes/authenticationStep";
import { VariantButton } from "@shopcashTypes/variantButton";
import clsx from "clsx";
import Link from "next/link";
import React, { useEffect, useState } from "react";

// TODO refactor
const MobileHeader: React.FC = () => {
  const { locale, isRtl, siteCode } = useSiteConfig();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [showSignUpModal, setShowSignUpModal] = useState<boolean>(false);
  const [showSignInModal, setShowSignInModal] = useState<boolean>(false);
  const [isWhatTheDiffShown, setIsWhatTheDiffShown] = useState(false);
  const [searchResults, setSearchResults] = useState<SearchResult>();
  const [searchInputValue, setSearchInputValue] = useState<string>();
  const debouncedSearchInputValue = useDebounce(searchInputValue);
  const [isNarrowSearchInputOpen, setIsNarrowSearchInputOpen] = useState<
    boolean
  >(false);
  const { user, accessToken } = useAuth();
  const [showWelcomeMessage, setShowWelcomeMessage] = useState<boolean>();
  const translate = useTranslations();
  const { trackEvent } = useGenzoTracking();

  useEffect(() => {
    if (debouncedSearchInputValue) {
      const callSearchStore = async (): Promise<void> => {
        const results = await searchShopCash(
          debouncedSearchInputValue,
          siteCode,
          locale
        );
        setSearchResults(results);
      };

      void callSearchStore();
      trackEvent(getSearchInputSearchEvent(debouncedSearchInputValue));
    } else {
      setSearchResults(null);
    }
  }, [debouncedSearchInputValue, locale]);

  useEffect(() => {
    if (user) {
      const welcomeMessageClosedBy = getCookie(
        `welcomeMessageClosedBy:${user.id}`
      );
      setShowWelcomeMessage(welcomeMessageClosedBy !== "true");
    }
  }, [user]);

  const handleOnWelcomeMesageClose = () => {
    if (user) {
      setCookie(`welcomeMessageClosedBy:${user.id}`, true);
    }

    setShowWelcomeMessage(false);
  };

  const handleOnMenuClick = (): void => {
    setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen);
  };

  const handleOnSignUpModalShow = (value: boolean): void => {
    setShowSignUpModal(value);
  };

  const handleOnSignInModalShow = (value: boolean): void => {
    setShowSignInModal(value);
  };

  const handleOnSearchClick = (): void => {
    setIsNarrowSearchInputOpen(
      (prevIsNarrowSearchInputOpen) => !prevIsNarrowSearchInputOpen
    );
  };

  const handleWhatsTheDiffShow = () => {
    setIsWhatTheDiffShown(true);
    setIsMenuOpen(false);
  };

  const handleWhatsTheDiffClose = () => {
    setIsWhatTheDiffShown(false);
    setIsMenuOpen(true);
  };

  return (
    <>
      <header
        data-testid="header-mobile"
        className={clsx(styles.header, isRtl && styles.rtl)}
      >
        <div className={styles.container}>
          <div className={styles.menuIconContainer} onClick={handleOnMenuClick}>
            <MenuIcon className={styles.menuIcon} />
          </div>

          {!isNarrowSearchInputOpen && (
            <>
              <div className={styles.logoAndSearch}>
                <Link href="/">
                  <a className={styles.logo}>
                    <img src="https://assets.wego.com/image/upload/w_280/v1608097107/web/shopcash/shopcash-logo-wording-white.png" />
                  </a>
                </Link>
              </div>
              <div className={styles.headerFunctionsContainer}>
                {user && (
                  <div className={styles.notificationBellContainer}>
                    <NotificationBell
                      mobile={true}
                      user={user}
                      accessToken={accessToken}
                    />
                  </div>
                )}

                <SearchIcon
                  className={styles.searchIcon}
                  onClick={handleOnSearchClick}
                />
              </div>
            </>
          )}

          {isNarrowSearchInputOpen && (
            <div className={styles.narrowSearchContainer}>
              <div className={styles.wrap}>
                <SearchInput
                  id="subSearchInput"
                  results={searchResults}
                  fullScreen
                  onValueChange={setSearchInputValue}
                />
                <div
                  className={styles.close}
                  onClick={() => setIsNarrowSearchInputOpen(false)}
                >
                  ✕
                </div>
              </div>
            </div>
          )}
        </div>
      </header>

      <div className={styles.placeholder}></div>

      <Drawer
        isOpen={isMenuOpen}
        isRtl={isRtl}
        fullScreen={true}
        onDrawerShow={(value) => setIsMenuOpen(value)}
        onSignUpOptionsFormOpen={() => handleOnSignUpModalShow(true)}
        onSignInOptionsFormOpen={() => handleOnSignInModalShow(true)}
        onWhatsTheDiffShow={handleWhatsTheDiffShow}
      />

      {showSignUpModal && (
        <AuthenticationModal
          show={showSignUpModal}
          onCloseClick={() => handleOnSignUpModalShow(false)}
        ></AuthenticationModal>
      )}

      {showSignInModal && (
        <AuthenticationModal
          show={showSignInModal}
          onCloseClick={() => handleOnSignInModalShow(false)}
          initialStep={AuthenticationStep.SignInOptionsStep}
        ></AuthenticationModal>
      )}

      {isWhatTheDiffShown && <WhatsTheDiff onClose={handleWhatsTheDiffClose} />}

      <Modal
        show={showWelcomeMessage}
        className={styles.welcomeMessageContainer}
      >
        <div
          className={clsx(styles.welcomeMessageContent, isRtl && styles.rtl)}
        >
          <CrossIcon onClick={handleOnWelcomeMesageClose} />
          <WelcomeMessage />
          <Button
            large
            variant={VariantButton.Primary}
            onClick={handleOnWelcomeMesageClose}
          >
            {translate("browse_shopcash")}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default MobileHeader;
