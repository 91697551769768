import Modal from "@components/Modal";
import { useSiteConfig } from "@context/siteConfig";
import { useTranslations } from "@context/translations";
import clsx from "clsx";
import { FC, useEffect, useState } from "react";
import CashBackItem from "./CashBackItem";
import styles from "./HowToUseCashBack.module.scss";

enum UserEducationSection {
  signUpForShopcashAccount = 1,
  shopAsUsual = 2,
  howToWithdrawYourCashback = 3,
  moreQuestions = 4,
}

interface HowToUseCashBackProps {
  onClose: () => void;
  firstPayout: Payout;
}

interface BonusThreshold {
  amount: number;
  currencyCode: string;
}

const modalStyle = {
  "<1024": {
    width: "560px",
    marginTop: "56px",
    maxHeight: "calc(100vh - 56px - 16px - 40px)",
    height: "auto",
  },
  ">=1024": {
    width: "560px",
    height: "auto",
  },
};

const HowToUseCashBack: FC<HowToUseCashBackProps> = ({
  onClose,
  firstPayout,
}) => {
  const { isMobile, isRtl, referrals } = useSiteConfig();
  const translate = useTranslations();
  const [minimumCashBackForSignUp, setMinimumCashBackForSignUp] = useState<
    BonusThreshold
  >();
  const isSignUpBonus: boolean = firstPayout?.payoutType === 3;
  const DATA = [
    UserEducationSection.signUpForShopcashAccount,
    UserEducationSection.shopAsUsual,
    UserEducationSection.howToWithdrawYourCashback,
    UserEducationSection.moreQuestions,
  ];

  useEffect(() => {
    if (isSignUpBonus) {
      minimumConfirmedCashbackAmount();
    }
  }, [firstPayout]);

  const minimumConfirmedCashbackAmount = () => {
    const regex = /\b[A-Z]{3} \d+\.?\d*/;
    const match = firstPayout?.message.match(regex);
    if (match && match[0]?.split(" ").length == 2) {
      setMinimumCashBackForSignUp({
        amount: Number(match[0]?.split(" ")[1]),
        currencyCode: match[0]?.split(" ")[0],
      });
    }
  };

  const boldTextTitleArray = isSignUpBonus
    ? [
        translate("signup_bonus_desc_step1"),
        translate("signup_bonus_desc_step2"),
        translate("signup_bonus_desc_step3"),
        translate("signup_bonus_desc_step4"),
      ]
    : [
        translate("signup_bonus_desc_step1"),
        translate("referral_bonus_desc_step2"),
        translate("referral_bonus_desc_step3"),
        translate("referral_bonus_desc_step4"),
      ];
  const descTextTitleArray = isSignUpBonus
    ? [
        "",
        translate("signup_bonus_desc_step2_exp"),
        translate("signup_bonus_desc_step3_exp", [
          minimumCashBackForSignUp?.amount,
          minimumCashBackForSignUp?.currencyCode,
        ]),
        translate("signup_bonus_desc_step4_exp"),
      ]
    : [
        "",
        translate("referral_bonus_desc_step2_exp"),
        translate("referral_bonus_desc_step3_exp", [
          referrals.refereeBalanceThreshold.amount,
          referrals.refereeBalanceThreshold.currencyCode,
          referrals.refereeBalanceThreshold.currencyCode,
          referrals.refereeBalanceThreshold.amount,
        ]),
        translate("referral_bonus_desc_step4_exp"),
      ];

  const itemForSection = (section: UserEducationSection) => {
    switch (section) {
      case UserEducationSection.signUpForShopcashAccount:
        return (
          <CashBackItem
            boldText={boldTextTitleArray[0]}
            descNormalText={descTextTitleArray[0]}
            key={`item-${section}`}
          />
        );
      case UserEducationSection.shopAsUsual:
        return (
          <CashBackItem
            boldText={boldTextTitleArray[1]}
            descNormalText={descTextTitleArray[1]}
            step={"2"}
            key={`item-${section}`}
          />
        );
      case UserEducationSection.howToWithdrawYourCashback:
        return (
          <CashBackItem
            boldText={boldTextTitleArray[2]}
            descNormalText={descTextTitleArray[2]}
            step={"3"}
            key={`item-${section}`}
          />
        );
      case UserEducationSection.moreQuestions:
        return (
          <CashBackItem
            boldText={boldTextTitleArray[3]}
            descNormalText={descTextTitleArray[3]}
            step={"4"}
            key={`item-${section}`}
          />
        );
    }
  };

  return (
    <Modal onCloseClick={onClose} modalStyle={modalStyle} fullScreen={isMobile}>
      <div className={clsx(styles.container, isRtl && styles.rtl)}>
        <div className={styles.header}>
          {translate("signup_bottomsheet_header")}
        </div>
        <div>{DATA.map((section) => itemForSection(section))}</div>
      </div>
    </Modal>
  );
};

export default HowToUseCashBack;
