import styles from "./Header.module.scss";
import LanguageSelector from "./LanguageSelector";
import Profile from "./Profile";
import SubHeader from "./SubHeader";
import { searchShopCash } from "@api/search";
import AuthenticationModal from "@components/AuthenticationModal";
import Heart from "@components/Heart";
import NotificationBell from "@components/NotificationBell/NotificationBell";
import SearchInput from "@components/SearchInput";
import WhatsTheDiff from "@components/WhatsTheDiff";
import { useAuth } from "@context/authentication";
import { useSiteConfig } from "@context/siteConfig";
import { useGenzoTracking } from "@context/tracking/genzo";
import { getSearchInputSearchEvent } from "@helpers/genzo/genzoEventActionsBuilder";
import useDebounce from "@helpers/useDebounce";
import { AuthenticationStep } from "@shopcashTypes/authenticationStep";
import { VariantHeart } from "@shopcashTypes/variantHeart";
import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";

const Header: React.FC = () => {
  const { locale, isRtl, siteCode } = useSiteConfig();
  const router = useRouter();
  const { user, accessToken } = useAuth();
  const [searchResults, setSearchResults] = useState<SearchResult>();
  const [searchInputValue, setSearchInputValue] = useState<string>();
  const debouncedSearchInputValue = useDebounce(searchInputValue);
  const [showSignUpOptionsModal, setShowSignUpOptionsModal] = useState<boolean>(
    false
  );
  const [showSignInOptionsModal, setShowSignInOptionsModal] = useState<boolean>(
    false
  );
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState<boolean>(false);
  const [isWhatTheDiffShown, setIsWhatTheDiffShown] = useState<boolean>(false);
  const [isFullWidthSearchOpen, setIsFullWidthSearchOpen] = useState<boolean>(
    false
  );
  const profileMenuRef = useRef<HTMLDivElement>(null);
  const { trackEvent } = useGenzoTracking();

  useEffect(() => {
    if (debouncedSearchInputValue) {
      const callSearch = async (): Promise<void> => {
        const results = await searchShopCash(
          debouncedSearchInputValue,
          siteCode,
          locale
        );
        setSearchResults(results);
      };

      void callSearch();
      trackEvent(getSearchInputSearchEvent(debouncedSearchInputValue));
    } else {
      setSearchResults(null);
    }
  }, [debouncedSearchInputValue, locale]);

  const handleOnJoinNowModalShow = (value: boolean): void => {
    setShowSignUpOptionsModal(value);
  };
  const handleOnSignInModalShow = (value: boolean): void => {
    setShowSignInOptionsModal(value);
  };

  const handleWhatsTheDiffShow = (): void => {
    setIsWhatTheDiffShown(true);
    setIsProfileMenuOpen(false);
  };

  const handleWhatsTheDiffClose = (): void => {
    setIsWhatTheDiffShown(false);
    setIsProfileMenuOpen(true);
    profileMenuRef.current.focus();
  };

  return (
    <>
      <div className={styles.headContainer}>
        <header
          data-testid="header-desktop"
          className={clsx(styles.header, isRtl && styles.rtl)}
        >
          <div
            className={clsx(
              styles.container,
              isFullWidthSearchOpen && styles.isFullWidthSearchOpen
            )}
          >
            <div className={styles.logoAndSearch}>
              <Link href="/">
                <a className={styles.logo} data-testid="header-logo">
                  <img src="https://assets.wego.com/image/upload/w_280/v1608097107/web/shopcash/shopcash-logo-wording-white.png" />
                </a>
              </Link>

              <div
                className={styles.searchContainer}
                onFocus={() => setIsFullWidthSearchOpen(true)}
              >
                <SearchInput
                  id="mainSearchInput"
                  results={searchResults}
                  onValueChange={setSearchInputValue}
                />
                <div
                  className={clsx(
                    styles.close,
                    isFullWidthSearchOpen && styles.isVisible
                  )}
                  onClick={() => setIsFullWidthSearchOpen(false)}
                >
                  ✕
                </div>
              </div>
            </div>

            <div className={styles.languageAndProfile}>
              <div className={styles.headerFunctionsContainer}>
                <LanguageSelector isRtl={isRtl} />

                {user && (
                  <>
                    <NotificationBell accessToken={accessToken} user={user} />
                    <Heart
                      variant={VariantHeart.White}
                      onClick={() => {
                        router.push({ pathname: `/account/favorite-stores` });
                      }}
                      hasHoverAnimation={false}
                      width="20"
                      height="20"
                    />
                  </>
                )}
              </div>

              <Profile
                onJoinNowClick={handleOnJoinNowModalShow}
                onSignInClick={handleOnSignInModalShow}
                isProfileMenuOpen={isProfileMenuOpen}
                setIsProfileMenuOpen={setIsProfileMenuOpen}
                onWhatsTheDiffShow={handleWhatsTheDiffShow}
                ref={profileMenuRef}
              />
            </div>
          </div>
        </header>

        <SubHeader />
      </div>

      {showSignUpOptionsModal && (
        <AuthenticationModal
          show={showSignUpOptionsModal}
          onCloseClick={() => handleOnJoinNowModalShow(false)}
        ></AuthenticationModal>
      )}

      {showSignInOptionsModal && (
        <AuthenticationModal
          initialStep={AuthenticationStep.SignInOptionsStep}
          show={showSignInOptionsModal}
          onCloseClick={() => handleOnSignInModalShow(false)}
        ></AuthenticationModal>
      )}

      {isWhatTheDiffShown && <WhatsTheDiff onClose={handleWhatsTheDiffClose} />}
    </>
  );
};

export default Header;
