import ActiveTickIcon from "@icons/activeTick.svg";
import React from "react";
import styles from "./CashBackItem.module.scss";

interface CashBackItemProps {
  showTickIcon?: boolean;
  boldText: string;
  descNormalText: string;
  step?: string;
}

const CashBackItem: React.FC<CashBackItemProps> = ({
  showTickIcon = true,
  boldText,
  descNormalText,
  step,
}): JSX.Element => {
  return (
    <div className={styles.container}>
      <div className={styles.textRowContainer}>
        {step ? (
          <div className={styles.customNumSteps}>{step}</div>
        ) : (
          showTickIcon && (
            <div className={styles.tickIcon}>
              <ActiveTickIcon />
            </div>
          )
        )}
        <p className={styles.boldText}>{boldText}</p>
      </div>
      <p className={styles.normalDescText}>{descNormalText}</p>
    </div>
  );
};
export default CashBackItem;
