import { useSiteConfig } from "@context/siteConfig";
import DesktopHeader from "./Header";
import MobileHeader from "./MobileHeader";

const Header = () => {
  const { isMobile } = useSiteConfig();
  return <>{isMobile ? <MobileHeader /> : <DesktopHeader />}</>;
};

export default Header;
