import Image from "@components/_shared/NextImage";
import { useSiteConfig } from "@context/siteConfig";
import { useTranslations } from "@context/translations";
import ChevronIcon from "@icons/chevron.svg";
import clsx from "clsx";
import React, { FC } from "react";
import styles from "./ReferAndEarnBanner.module.scss";

type ReferAndEarnBannerProps = {
  // TBD
};

const ReferAndEarnBanner: FC<ReferAndEarnBannerProps> = () => {
  const translate = useTranslations();
  const { referrals, isRtl } = useSiteConfig();
  const currencyCode = referrals?.referralPayout?.currencyCode || "";
  const payoutAmount = referrals?.referralPayout?.amount || "";
  const referAndEarnValue = `${currencyCode} ${payoutAmount}`.trim();

  if (!currencyCode || !payoutAmount) return null;

  return (
    <div className={clsx(styles.container, isRtl && styles.isRtl)}>
      <Image
        src={
          "https://assets.wego.com/image/upload/w_216/v1604981660/web/shopcash/Illustration-earn.png"
        }
        height={72}
        width={72}
        flipped={isRtl}
      ></Image>
      <div className={styles.copySection}>
        <div className={styles.text}>
          <span>{translate("refer_and_earn_up_to", [referAndEarnValue])}</span>
          <span>{translate("refer_drawer_button_subtitle")}</span>
        </div>
        <ChevronIcon className={styles.chevron} />
      </div>
    </div>
  );
};

export default ReferAndEarnBanner;
