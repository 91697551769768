import { useSiteConfig } from "@context/siteConfig";
import { useTranslations } from "@context/translations";
import Chevron from "@icons/chevron.svg";
import { FC } from "react";
import styles from "./HowToUseCashbackCard.module.scss";

interface HowToUseCashbackCardProps {
  handleUserEducationSignUpBonusOpen: () => void;
}

const HowToUseCashbackCard: FC<HowToUseCashbackCardProps> = ({
  handleUserEducationSignUpBonusOpen,
}) => {
  const translate = useTranslations();
  const { isRtl } = useSiteConfig();

  const handleClick = () => {
    handleUserEducationSignUpBonusOpen();
  };

  return (
    <div className={styles.container} onClick={handleClick}>
      <img
        src="https://assets.wego.com/image/upload/v1607931359/web/shopcash/illustrations-shop-and-earn.png"
        alt="Shop and earn illustration"
        width={52}
        height={52}
      />
      <div className={styles.text}>
        {translate("signup_and_referral_dropdown_link")}
      </div>
      <Chevron className={isRtl ? styles.rtlChevron : styles.chevron} />
    </div>
  );
};

export default HowToUseCashbackCard;
