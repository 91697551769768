import { getCategories } from "@api/categories";
import CategoryMenu from "@components/CategoryMenu";
import { useAuth } from "@context/authentication";
import { useSiteConfig } from "@context/siteConfig";
import { useGenzoTracking } from "@context/tracking/genzo";
import { useTranslations } from "@context/translations";
import { getExtensionInstallClickEvent } from "@helpers/genzo/genzoEventActionsBuilder";
import { isChromeBrowser } from "@helpers/isChromeBrowser";
import useScrollDownDetector from "@helpers/useScrollDownDetector";
import ChromeIcon from "@icons/chromeLogo.svg";
import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import useSWR from "swr";
import styles from "./SubHeader.module.scss";

const SubHeader: React.FC = () => {
  const router = useRouter();
  const { locale, isRtl, referrals } = useSiteConfig();
  const translate = useTranslations();
  const [isCategoryMenuOpen, setIsCategoryMenuOpen] = useState(false);
  const { hasScrolledDown } = useScrollDownDetector();
  const { trackEvent } = useGenzoTracking();

  const { user } = useAuth();
  const { data: categoriesResponse } = useSWR(locale, getCategories);

  const { categories } = categoriesResponse || {};

  const currencyCode = referrals?.referralPayout?.currencyCode || "";
  const payoutAmount = referrals?.referralPayout?.amount || "";
  const referAndEarnValue = `${currencyCode} ${payoutAmount}`.trim();

  const [shouldShowChromeLink, setShouldShowChromeLink] = useState<boolean>(
    false
  );

  useEffect(() => {
    setShouldShowChromeLink(isChromeBrowser());
  }, []);

  const handleChromeLinkClick = (): void => {
    trackEvent(getExtensionInstallClickEvent());
  };

  return (
    <div
      className={clsx(
        styles.subHeader,
        hasScrolledDown && styles.hide,
        isRtl && styles.rtl
      )}
    >
      <div className={clsx(styles.container)}>
        <div
          tabIndex={0}
          className={clsx(
            styles.categoriesContainer,
            isCategoryMenuOpen && styles.open
          )}
          onBlur={(e) => {
            if (!e.currentTarget.contains(e.relatedTarget as Node)) {
              setIsCategoryMenuOpen(false);
            }
          }}
          onClick={() => {
            setIsCategoryMenuOpen(
              (prevIsCategoryMenuOpen) => !prevIsCategoryMenuOpen
            );
          }}
        >
          <div className={styles.label}>
            {translate("categories")}
            <span className={styles.arrowIcon}></span>
          </div>
          {isCategoryMenuOpen && (
            <div className={styles.menuContainer}>
              <CategoryMenu
                categories={categories || []}
                title={translate("all_categories_title")}
              />
            </div>
          )}
        </div>

        <span className={styles.divider}></span>

        <nav className={styles.navs} data-testid="sub-header-navs">
          <div
            className={clsx(
              styles.referAndEarn,
              styles.nav,
              router.pathname === "/wallet/refer" && styles.selected
            )}
          >
            <Link href={user ? "/wallet/refer" : "/refer"}>
              <a>{translate("refer_and_earn_up_to", [referAndEarnValue])}</a>
            </Link>
          </div>
          <div
            className={clsx(
              styles.nav,
              router.pathname === "/popular-stores" && styles.selected
            )}
          >
            <Link href="/popular-stores">
              <a data-testid="popular-stores-link">
                {translate("trending_stores_title")}
              </a>
            </Link>
          </div>

          <div
            className={clsx(
              styles.nav,
              router.pathname === "/how-to-earn" && styles.selected
            )}
          >
            <Link href="/how-to-earn">
              <a data-testid="how-to-earn-link">
                {translate("how_to_earn_sorting_option")}
              </a>
            </Link>
          </div>
          <div
            className={clsx(
              styles.nav,
              router.pathname === "/coupons/[categorySlug]" && styles.selected
            )}
          >
            <Link href="/coupons/all-stores">
              <a>{translate("coupons")}</a>
            </Link>
          </div>
          <div
            className={clsx(
              styles.nav,
              router.pathname === "/deals" && styles.selected
            )}
          >
            <Link href="/deals">
              <a>{translate("deals")}</a>
            </Link>
          </div>
          {shouldShowChromeLink && (
            <div
              className={clsx(styles.nav, styles.chromeLink, styles.selected)}
            >
              <a
                href="https://chromewebstore.google.com/detail/shopcash-cashback-coupons/kjblmmkfofjokionfgolkolihfpbhenl"
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleChromeLinkClick}
              >
                <ChromeIcon className={styles.chromeLogo} />
                {translate("add_to_chrome")}
              </a>
            </div>
          )}
        </nav>
      </div>
    </div>
  );
};

export default SubHeader;
