import Button from "@components/Button";
import ReferAndEarnBanner from "@components/ReferAndEarnBanner";
import WalletSummary from "@components/WalletSummary";
import { useAuth } from "@context/authentication";
import { useSiteConfig } from "@context/siteConfig";
import { useGenzoTracking } from "@context/tracking/genzo";
import { useWebEngageTracking } from "@context/tracking/webEngage";
import { useTranslations } from "@context/translations";
import {
  getHeaderSignInEvent,
  getHeaderSignUpEvent,
} from "@helpers/genzo/genzoEventActionsBuilder";
import { buildLanguageClickAttrs } from "@helpers/webEngage/clickEventBuilders";
import AccountIcon from "@icons/account.svg";
import CheckIcon from "@icons/check.svg";
import ChevronIcon from "@icons/chevron.svg";
import FavouriteIcon from "@icons/favourite.svg";
import HomeIcon from "@icons/home.svg";
import StoreIcon from "@icons/store.svg";
import WalletIcon from "@icons/wallet.svg";
import { VariantButton } from "@shopcashTypes/variantButton";
import {
  WebEngageClickEvent,
  WebEngageUserEventType,
} from "@shopcashTypes/webEngageTracking";
import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import styles from "./Drawer.module.scss";

interface DrawerProps {
  isOpen: boolean;
  isRtl: boolean;
  fullScreen?: boolean;
  onDrawerShow: (value: boolean) => void;
  onSignUpOptionsFormOpen: () => void;
  onSignInOptionsFormOpen: () => void;
  onWhatsTheDiffShow: () => void;
}

const Drawer: React.FC<DrawerProps> = ({
  isOpen,
  isRtl,
  fullScreen,
  onDrawerShow,
  onSignUpOptionsFormOpen,
  onSignInOptionsFormOpen,
  onWhatsTheDiffShow,
}) => {
  const router = useRouter();
  const { user, logout, isAuthenticating } = useAuth();
  const { defaultLocale } = useSiteConfig();
  const [displayDrawer, setDisplayDrawer] = useState<boolean>(false);
  const translate = useTranslations();

  const { trackEvent, trackUserEvent } = useGenzoTracking();
  const {
    trackWebEngageUserEvent,
    trackWebEngageClickEvent,
  } = useWebEngageTracking();

  const referLink = user ? "/wallet/refer" : "/refer";

  useEffect(() => {
    if (!displayDrawer) {
      setDisplayDrawer(true);
    }

    return () => {
      setDisplayDrawer(false);
    };
  }, []);

  useEffect(() => {
    const bodyClassList = document.body.classList;

    if (isOpen) {
      bodyClassList.add("bodyScrollDisabled");
    }

    return () => {
      bodyClassList.remove("bodyScrollDisabled");
    };
  }, [isOpen]);

  const handleSignUpEvent = () => {
    const event = getHeaderSignUpEvent();
    trackEvent(event);
  };
  const handleSignInEvent = () => {
    const event = getHeaderSignInEvent();
    trackEvent(event);
  };

  const handleOnJoinNowClick = () => {
    handleSignUpEvent();
    onDrawerShow(false);
    onSignUpOptionsFormOpen();
  };

  const handleOnSignInClick = () => {
    handleSignInEvent();
    onDrawerShow(false);
    onSignInOptionsFormOpen();
  };

  const handleOnTabClick = (path: string, hasAuthRestriction?: boolean) => {
    onDrawerShow(false);

    if (hasAuthRestriction && !user) {
      onSignUpOptionsFormOpen();
    } else {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      router.push({ pathname: path });
    }
  };

  const handleOnLogoutClick = () => {
    trackUserEvent("USER_LOGOUT");
    trackWebEngageUserEvent({ type: WebEngageUserEventType.Logout });
    logout();
    onDrawerShow(false);
  };

  const handleLocaleClick = (locale: string) => {
    trackWebEngageClickEvent(
      WebEngageClickEvent.languageClick,
      buildLanguageClickAttrs(locale)
    );
  };

  return (
    <>
      <div
        className={clsx(
          styles.drawerContainer,
          displayDrawer && styles.display,
          isOpen && styles.open,
          fullScreen && styles.fullScreen,
          isRtl && styles.rtl
        )}
      >
        {onDrawerShow && (
          <div className={styles.drawer}>
            <div className={styles.close} onClick={() => onDrawerShow(false)}>
              <ChevronIcon /> {translate("close_title")}
            </div>

            <WalletSummary onWhatsTheDiffShow={onWhatsTheDiffShow} />

            {/* when still authenticating, display loader */}
            {!user && isAuthenticating && null}

            {/* when done authenticating and still no user, display login/signup button */}
            {!user && !isAuthenticating && (
              <div className={styles.authOptions}>
                <div className={styles.button}>
                  <Button
                    variant={VariantButton.RoundedSecondary}
                    large
                    fullWidth
                    onClick={handleOnJoinNowClick}
                  >
                    {translate("join_now")}
                  </Button>
                </div>
                <div className={styles.button}>
                  <Button
                    variant={VariantButton.RoundedOutlineSecondary}
                    large
                    fullWidth
                    onClick={handleOnSignInClick}
                  >
                    {translate("onboarding_signin")}
                  </Button>
                </div>
              </div>
            )}

            <ul className={styles.tabs}>
              <li
                className={clsx(
                  styles.tab,
                  router.pathname === "/" && styles.selected
                )}
                onClick={() => handleOnTabClick("/")}
                onMouseEnter={() => {
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  router.prefetch("/");
                }}
              >
                <HomeIcon /> {translate("home")}
              </li>

              <li
                className={clsx(
                  styles.tab,

                  router.pathname === "/category/[categorySlug]" &&
                    router.query.categorySlug === "all-stores" &&
                    styles.selected
                )}
                onClick={() => handleOnTabClick("/category/all-stores")}
                onMouseEnter={() => {
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  router.prefetch("/category/all-stores");
                }}
              >
                <StoreIcon /> {translate("categories")}
              </li>

              <li
                className={clsx(
                  styles.tab,
                  router.pathname === "/wallet" && styles.selected
                )}
                onClick={() => handleOnTabClick("/wallet", true)}
                onMouseEnter={() => {
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  router.prefetch("/wallet");
                }}
              >
                <WalletIcon /> {translate("wallet")}
              </li>

              <li
                className={clsx(
                  styles.tab,
                  router.pathname === "/account/favorite-stores" &&
                    styles.selected
                )}
                onClick={() =>
                  handleOnTabClick("/account/favorite-stores", true)
                }
                onMouseEnter={() => {
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  router.prefetch("/account/favorite-stores");
                }}
              >
                <FavouriteIcon /> {translate("favorite_stores_title")}
              </li>
              <li
                className={clsx(
                  styles.tab,
                  router.pathname === "/account" && styles.selected
                )}
                onClick={() => handleOnTabClick("/account", true)}
                onMouseEnter={() => {
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  router.prefetch("/account");
                }}
              >
                <AccountIcon /> {translate("account")}
              </li>
            </ul>
            <ul
              className={styles.languages}
              data-testid="language-selector-drawer"
            >
              <li>
                <Link
                  href={{
                    pathname: router.pathname,
                    query: router.query,
                  }}
                  locale={defaultLocale.includes("en") ? defaultLocale : "en"}
                >
                  <a
                    className={clsx(
                      styles.language,
                      router.locale.includes("en") && styles.selected
                    )}
                    onClick={() => handleLocaleClick("English")}
                  >
                    <span>English</span>

                    <CheckIcon className={clsx(styles.checkIcon)} />
                  </a>
                </Link>
              </li>

              <li>
                <Link
                  href={{
                    pathname: router.pathname,
                    query: router.query,
                  }}
                  locale={defaultLocale.includes("ar") ? defaultLocale : "ar"}
                >
                  <a
                    className={clsx(
                      styles.language,
                      router.locale.includes("ar") && styles.selected
                    )}
                    onClick={() => handleLocaleClick("Arabic")}
                  >
                    <span>{translate("arabic")}</span>

                    <CheckIcon className={clsx(styles.checkIcon)} />
                  </a>
                </Link>
              </li>
            </ul>
            <div
              className={styles.referAndEarn}
              onClick={() => handleOnTabClick(referLink, false)}
              onMouseEnter={() => {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                router.prefetch(referLink);
              }}
            >
              <ReferAndEarnBanner />
            </div>
            <div
              className={clsx(
                styles.solo,
                router.pathname === "/how-to-earn" && styles.selected
              )}
              onClick={() => handleOnTabClick("/how-to-earn", false)}
              onMouseEnter={() => {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                router.prefetch("/how-to-earn");
              }}
            >
              {translate("how_to_earn_sorting_option")}
            </div>
            <div
              className={clsx(
                styles.solo,
                router.pathname === "/popular-stores" && styles.selected
              )}
              onClick={() => handleOnTabClick("/popular-stores", false)}
              onMouseEnter={() => {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                router.prefetch("/popular-stores");
              }}
            >
              {translate("trending_stores_title")}
            </div>

            <div
              className={clsx(
                styles.solo,
                router.pathname === "/coupons/[categorySlug]" && styles.selected
              )}
              onClick={() => handleOnTabClick("/coupons/all-stores", false)}
              onMouseEnter={() => router.prefetch("/coupons/all-stores")}
            >
              {translate("coupons")}
            </div>

            <div
              className={clsx(
                styles.solo,
                router.pathname === "/deals" && styles.selected
              )}
              onClick={() => handleOnTabClick("/deals", false)}
              onMouseEnter={() => {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                router.prefetch("/deals");
              }}
            >
              {translate("deals")}
            </div>
            <a
              className={styles.solo}
              href="https://shopcash.blog/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {translate("Blog")}
            </a>

            {!!user && (
              <div className={styles.logout}>
                <span onClick={handleOnLogoutClick}>{translate("logout")}</span>
              </div>
            )}
          </div>
        )}
      </div>

      <div
        className={clsx(styles.drawerScrim, isOpen && styles.open)}
        onClick={() => {
          onDrawerShow(false);
        }}
      ></div>
    </>
  );
};

export default Drawer;
