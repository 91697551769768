import { API_HOST } from "./apiHelpers";

interface NotificationsListResponse {
  notifications: Array<Notification>;
  pagination: Pagination;
}

const parseListResponse = (body): NotificationsListResponse => ({
  notifications: body["data"],
  pagination: body["meta"],
});

export const getNotifications = (
  accessToken: string,
  locale = "en",
  timezoneOffset = 0,
  page = 1,
  perPage = 10
): Promise<NotificationsListResponse> => {
  const params = [
    `language=${locale}`,
    `perPage=${perPage}`,
    `page=${page}`,
    `timezoneOffset=${timezoneOffset}`,
  ].join("&");

  return fetch(`${API_HOST}/account/notifications?${params}`, {
    headers: new Headers({ Authorization: `Bearer ${accessToken}` }),
  })
    .then((r) => r.json())
    .then(parseListResponse)
    .catch((e) => {
      console.error(e);

      return {
        notifications: [],
        pagination: {},
      };
    });
};

const parseIndicatorResponse = (body) => body.data?.showIndicator;

export const showIndicator = (accessToken: string): Promise<boolean> => {
  return fetch(`${API_HOST}/account/notifications/indicator`, {
    headers: new Headers({ Authorization: `Bearer ${accessToken}` }),
  })
    .then((r) => r.json())
    .then(parseIndicatorResponse)
    .catch((e) => {
      console.error(e);
      return null;
    });
};

export const markAllAsRead = (accessToken: string): Promise<null> => {
  return fetch(`${API_HOST}/account/notifications/mark-all-as-read`, {
    headers: new Headers({ Authorization: `Bearer ${accessToken}` }),
    method: "POST",
  })
    .then((r) => r)
    .catch((e) => {
      console.error(e);
      return null;
    });
};
