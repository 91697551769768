import { useEffect, useState } from "react";

const DELAY = 400;

const useDebounce = (value: string, delay: number = DELAY): string => {
  const [debouncedValue, setDebouncedValue] = useState<string>();

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
};

export default useDebounce;
