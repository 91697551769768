import { useEffect, useState } from "react";

interface UseScrollDownDetector {
  hasScrolledDown: boolean;
}

// https://stackoverflow.com/questions/62497110/detect-scroll-direction-in-react-js
const useScrollDownDetector = (): UseScrollDownDetector => {
  const [isSubHeaderEffectEnabled, setIsSubHeaderEffectEnabled] = useState<
    boolean
  >(false);
  const [hasScrolledDown, setHasScrolledDown] = useState<boolean>(false);

  useEffect(() => {
    // Enable the effect after 3 seconds, this is to let the page settle first before initializing the behavior.
    // If we don't do this, the subHeader will be shaky due to layout shift.
    const delaySubHeaderEffect = setTimeout(
      () => setIsSubHeaderEffectEnabled(true),
      3000
    );

    return () => clearTimeout(delaySubHeaderEffect);
  }, []);

  useEffect(() => {
    const threshold = 0;
    let lastScrollY: number = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = (): void => {
      const scrollY: number = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setHasScrolledDown(scrollY > lastScrollY);
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = (): void => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    if (isSubHeaderEffectEnabled) {
      window.addEventListener("scroll", onScroll);
    }

    return () => window.removeEventListener("scroll", onScroll);
  }, [isSubHeaderEffectEnabled]);

  return { hasScrolledDown };
};

export default useScrollDownDetector;
