import { getNotifications as listNotifications } from "@api/notifications";
import Button from "@components/Button";
import NotificationList from "@components/NotificationList";
import Modal from "@components/_shared/Modal";
import { useAuth } from "@context/authentication";
import { useSiteConfig } from "@context/siteConfig";
import { useGenzoTracking } from "@context/tracking/genzo";
import { useTranslations } from "@context/translations";
import {
  getBrowseStoresEvent,
  getSeeAllNotificationsEvent,
} from "@helpers/genzo/genzoEventActionsBuilder";
import { VariantButton } from "@shopcashTypes/variantButton";
import clsx from "clsx";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import styles from "./NotificationModal.module.scss";

interface NotificationModalProps {
  onClose: () => void;
  show: boolean;
}

const NotificationModal: React.FC<NotificationModalProps> = ({
  onClose,
  show,
}) => {
  const [notifications, setNotifications] = useState(undefined);
  const translate = useTranslations();
  const router = useRouter();
  const { accessToken } = useAuth();
  const { locale, isRtl } = useSiteConfig();
  const { trackEvent } = useGenzoTracking();

  useEffect(() => {
    if (show) {
      const timezoneOffset = new Date().getTimezoneOffset();
      listNotifications(accessToken, locale, timezoneOffset, 1, 3)
        .then((results) => setNotifications(results.notifications))
        .catch((e) => console.error(e));
    }
  }, [show]);

  const handleSeeAll = async () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    router.push({
      pathname: `/account/notifications`,
    });

    handleSeeAllEvent();
    await onClose();
  };

  const handleSeeAllEvent = () => {
    const event = getSeeAllNotificationsEvent();
    trackEvent(event);
  };

  const handleBrowseAll = async () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    router.push({
      pathname: `/category/all-stores`,
    });

    handleBrowseAllEvent();
    await onClose();
  };

  const handleBrowseAllEvent = () => {
    const event = getBrowseStoresEvent();
    trackEvent(event);
  };

  return (
    <Modal show={show} className={styles.modal}>
      <div className={styles.notificationModal}>
        <div
          className={clsx(styles.close, isRtl && styles.rtl)}
          onClick={onClose}
        >
          {translate("close_title")}
        </div>
        <h6 className={styles.header}>{translate("title_notifications")}</h6>
        <div
          className={clsx(
            styles.listContainer,
            notifications?.length == 0 && styles.empty
          )}
        >
          <div
            className={clsx(
              styles.body && notifications !== undefined && styles.fadeIn
            )}
          >
            <NotificationList
              compact
              notifications={notifications}
              loadingCount={1}
            />
          </div>
          <div className={styles.footer}>
            {notifications?.length == 0 && (
              <div className={styles.fadeIn}>
                <Button
                  variant={VariantButton.Primary}
                  onClick={handleBrowseAll}
                  fullWidth
                >
                  {translate("browse_stores")}
                </Button>
              </div>
            )}
            {notifications?.length > 0 && (
              <div className={styles.fadeIn}>
                <Button
                  variant={VariantButton.Primary}
                  onClick={handleSeeAll}
                  fullWidth
                >
                  {translate("notif_title_see_all")}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NotificationModal;
