import TextDirection from "@components/_shared/TextDirection";
import { useSiteConfig } from "@context/siteConfig";
import clsx from "clsx";
import { dateDisplayFormat } from "./../../helpers/date";
import styles from "./NotificationItem.module.scss";

interface NotificationItemProps {
  title?: string;
  body?: string;
  insertedAt?: string;
  isLoading?: boolean;
  compact?: boolean;
}

const NotificationItem: React.FC<NotificationItemProps> = ({
  title,
  body,
  insertedAt,
  isLoading = false,
  compact = false,
}) => {
  const { locale } = useSiteConfig();
  const createMarkup = (html: string) => {
    return { __html: html };
  };

  const { isRtl } = useSiteConfig();
  return (
    <div
      className={clsx(
        styles.container,
        compact && styles.compact,
        isLoading && styles.isLoading,
        isRtl && styles.rtl
      )}
    >
      <div className={styles.title}>
        <TextDirection text={title} />
      </div>

      <div
        className={styles.content}
        dangerouslySetInnerHTML={createMarkup(body)}
      ></div>
      <div className={styles.date}>
        {insertedAt && dateDisplayFormat(insertedAt, locale)}
      </div>
    </div>
  );
};

export default NotificationItem;
