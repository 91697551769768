import { useAuth } from "@context/authentication";
import { useGenzoTracking } from "@context/tracking/genzo";
import { useTranslations } from "@context/translations";
import { getWelcomeMessageBonusClickEvent } from "@helpers/genzo/genzoEventActionsBuilder";
import React from "react";
import styles from "./WelcomeMessage.module.scss";

interface WelcomeMessageProps {
  showUserEducationSignUpBonus?: () => void;
  firstPayout?: Payout;
}

const WelcomeMessage: React.FC<WelcomeMessageProps> = ({
  showUserEducationSignUpBonus,
  firstPayout,
}) => {
  const {
    user: {
      profile: { name },
    },
  } = useAuth();
  const translate = useTranslations();
  const { trackEvent } = useGenzoTracking();

  const handleSignUpBonusClick = () => {
    trackEvent(getWelcomeMessageBonusClickEvent(firstPayout.payoutType));

    if (showUserEducationSignUpBonus) {
      showUserEducationSignUpBonus();
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {translate("welcome_title", [name || ""])} 🎉
      </div>
      {firstPayout?.payoutType == 2 || firstPayout?.payoutType == 3 ? (
        <div>
          {translate("signup_and_referral_bonus_msg", [
            firstPayout.currencyCode,
            firstPayout.value,
          ])}
          &nbsp;
          <span className={styles.titleLink} onClick={handleSignUpBonusClick}>
            {translate("signup_and_referral_bonus_msg1")}
          </span>
        </div>
      ) : (
        <div>{translate("welcome_body")}</div>
      )}
    </div>
  );
};

export default WelcomeMessage;
