import styles from "./NotificationBell.module.scss";
import NotificationModal from "./NotificationModal";
import NotificationToolTip from "./NotificationToolTip";
import { markAllAsRead, showIndicator } from "@api/notifications";
import { useAuth } from "@context/authentication";
import { useGenzoTracking } from "@context/tracking/genzo";
import { getClickNotificationsBellEvent } from "@helpers/genzo/genzoEventActionsBuilder";
import NotificationIcon from "@icons/notificationBell.svg";
import NotificationIconActive from "@icons/notificationBellActive.svg";
import clsx from "clsx";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

interface NotificationBellProps {
  accessToken: string;
  user: User;
  mobile?: boolean;
}

const NotificationBell: React.FC<NotificationBellProps> = ({
  user,
  accessToken,
  mobile = false,
}) => {
  const [unread, setUnread] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);

  const { trackEvent } = useGenzoTracking();
  const router = useRouter();

  // check for unread on mount
  useEffect(() => {
    if (router.pathname == "/account/notifications") {
      return setUnread(false);
    }
    if (accessToken && !unread) {
      checkForUnread();
    }
  }, [user, router.pathname]);

  const checkForUnread = async () => {
    const result = await showIndicator(accessToken);
    setUnread(result);
  };

  const handleOnClick = () => {
    handleClickNotificationBellEvent();
    setShowNotifications(!showNotifications);
    if (unread) {
      markAllAsRead(accessToken);
      setUnread(false);
    }
  };

  const handleClickNotificationBellEvent = () => {
    const event = getClickNotificationsBellEvent();
    trackEvent(event);
  };

  const handleOnClose = () => {
    setShowNotifications(false);
  };

  return (
    <div
      className={clsx(
        styles.notificationBellContainer,
        mobile && styles.mobile
      )}
      onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget as Node) && !mobile) {
          handleOnClose();
        }
      }}
      tabIndex={-1}
    >
      <div className={styles.notificationBellWrapper}>
        <div
          className={clsx(styles.notificationBellIcon, !user && styles.hide)}
          onClick={handleOnClick}
          data-testid="notification-bell-icon"
        >
          {unread ? <NotificationIconActive /> : <NotificationIcon />}
        </div>
        {showNotifications && !mobile && (
          <NotificationToolTip onClose={handleOnClose} />
        )}
      </div>
      <NotificationModal
        show={showNotifications && mobile}
        onClose={handleOnClose}
      />
    </div>
  );
};

export default NotificationBell;
